export const updateThemeColor = (color: string): void => {
  let themeColorMetaTag = document.querySelector(
    'meta[name="theme-color"]'
  ) as HTMLMetaElement | null;
  if (!themeColorMetaTag) {
    themeColorMetaTag = document.createElement("meta") as HTMLMetaElement;
    themeColorMetaTag.name = "theme-color";
    document.head.appendChild(themeColorMetaTag);
  }
  themeColorMetaTag.content = color;
};
