import React from "react";
import { Navbar, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds a smooth scrolling effect *
    });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="header">
      <Navbar.Brand
        className="ms-3 custom-brand"
        onClick={scrollToTop}
        style={{ cursor: "pointer" }}
      >
        <picture>
          <source srcSet="/rx_big_new_no_bg.avif" type="image/avif" />
          <source srcSet="/rx_big_new_no_bg.webp" type="image/webp" />
          <img
            src="/rx_big_new_no_bg.png"
            style={{ height: "80px", marginRight: "5px", marginBottom: "3px" }}
            alt="Brand"
          />
        </picture>
      </Navbar.Brand>
      <div className="ms-auto me-3">
        <Button
          as="a"
          variant="outline-light"
          href="https://live.idkrx.app"
          target="_blank"
          rel="noopener noreferrer"
          className="custom-button"
        >
          Sign in <FontAwesomeIcon icon={faSignInAlt} className="ms-2" />
        </Button>
      </div>
    </Navbar>
  );
};

export default Header;
