import React, { useEffect, useRef, useState } from "react";
import "./ScrollContent.css";

const ScrollContent: React.FC<{
  children: React.ReactNode;
  direction: "left" | "right";
}> = ({ children, direction }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div ref={ref} className="scroll-container">
      <div
        className={`scroll-content ${direction} ${
          isVisible ? "appear" : "disappear"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollContent;
