// // export default DocsLayout;
// import { Link, Navigate, Route, Routes } from "react-router-dom";
// import { Navbar, Container, Nav } from "react-bootstrap";
// import React, { useState, useEffect } from "react";
// import { updateThemeColor } from "./lib/theme";
// import Markdown from "markdown-to-jsx";
// import Footer from "./Footer";
// import "./index.css";

// interface DocContentProps {
//   filePath: string;
// }

// const DocContent: React.FC<DocContentProps> = ({ filePath }) => {
//   const [content, setContent] = useState<string>("");
//   const [transitionClass, setTransitionClass] = useState<string>("fade-enter");

//   useEffect(() => {
//     const color = "rgba(33,37,41, 1)";
//     updateThemeColor(color);
//   }, []);

//   useEffect(() => {
//     const fetchContent = async () => {
//       if (filePath) {
//         const response = await fetch(filePath);
//         if (response.ok) {
//           const text = await response.text();
//           setContent(text);
//         } else {
//           setContent("Failed to load content.");
//         }
//       } else {
//         setContent("");
//       }
//     };

//     setTransitionClass("fade-exit");
//     setTimeout(() => {
//       fetchContent();
//       setTransitionClass("fade-enter");
//     }, 500);
//   }, [filePath]);

//   useEffect(() => {
//     if (transitionClass === "fade-enter") {
//       const timer = setTimeout(() => {
//         setTransitionClass("");
//       }, 500);
//       return () => clearTimeout(timer);
//     }
//   }, [transitionClass]);

//   return (
//     <div className={`content ${transitionClass}`}>
//       <Markdown>{content}</Markdown>
//     </div>
//   );
// };

// const DocsLayout = () => {
//   return (
//     <div className="docs-layout">
//       <Navbar bg="dark" data-bs-theme="dark">
//         <Container>
//           <Navbar.Brand as={Link} to="/">
//             <span style={{ color: "#ff0977" }}>idkRx</span>
//           </Navbar.Brand>
//           <Nav className="me-auto">
//             <Nav.Link as={Link} to="/docs/terms">
//               <span style={{ color: "white" }}>Terms of Service</span>
//             </Nav.Link>
//             <Nav.Link as={Link} to="/docs/privacy">
//               <span style={{ color: "white" }}>Privacy Policy</span>
//             </Nav.Link>
//             <Nav.Link as={Link} to="/docs/about">
//               <span style={{ color: "white" }}>About Us</span>
//             </Nav.Link>
//           </Nav>
//         </Container>
//       </Navbar>

//       <main className="main-content">
//         <Routes>
//           <Route path="/" element={<Navigate to="/docs/terms" replace />} />
//           <Route
//             path="terms"
//             element={<DocContent filePath="/docs/TermsOfService.md" />}
//           />
//           <Route
//             path="privacy"
//             element={<DocContent filePath="/docs/PrivacyPolicy.md" />}
//           />
//           <Route
//             path="about"
//             element={<DocContent filePath="/docs/AboutUs.md" />}
//           />
//         </Routes>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default DocsLayout;
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { updateThemeColor } from "./lib/theme";
import Markdown from "markdown-to-jsx";
import Footer from "./Footer";
// import "./index.css";

interface DocContentProps {
  filePath: string;
}

const DocContent: React.FC<DocContentProps> = ({ filePath }) => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const color = "rgba(33,37,41, 1)";
    updateThemeColor(color);
  }, []);

  useEffect(() => {
    const fetchContent = async () => {
      if (filePath) {
        const response = await fetch(filePath);
        if (response.ok) {
          const text = await response.text();
          setContent(text);
        } else {
          setContent("Failed to load content.");
        }
      } else {
        setContent("");
      }
    };

    fetchContent();
  }, [filePath]);

  return (
    <div className="content">
      <Markdown>{content}</Markdown>
    </div>
  );
};

const DocsLayout = () => {
  return (
    <div className="docs-layout">
      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <span style={{ color: "#ff0977" }}>idkRx</span>
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/docs/terms">
              <span style={{ color: "white" }}>Terms of Service</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/docs/privacy">
              <span style={{ color: "white" }}>Privacy Policy</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/docs/about">
              <span style={{ color: "white" }}>About Us</span>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <main className="main-content">
        <Routes>
          <Route path="/" element={<Navigate to="/docs/terms" replace />} />
          <Route
            path="terms"
            element={<DocContent filePath="/docs/TermsOfService.md" />}
          />
          <Route
            path="privacy"
            element={<DocContent filePath="/docs/PrivacyPolicy.md" />}
          />
          <Route
            path="about"
            element={<DocContent filePath="/docs/AboutUs.md" />}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default DocsLayout;
