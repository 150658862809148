import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./index.css";
import Header from "./Header";
import Footer from "./Footer";
import ScrollContent from "./ScrollContent";
import { updateThemeColor } from "./lib/theme";

const Home: React.FC = () => (
  <div className="home-content">
    <h1 className="header-text">End Prescription Purgatory.</h1>
    <h3 className="subtitle">
      Real-time Rx reporting for your local pharmacies.
    </h3>
    <div>
      <ScrollContent direction="left">
        <div className="section-content">
          <video className="graphic" autoPlay loop muted playsInline>
            <source src="/idkrx-landing-graphics-2.webm" type="video/webm" />
            <source src="/idkrx-landing-graphics-2.mp4" type="video/mp4" />
            Your browser does not support the video tag. See your medications.
          </video>

          {/* <img
            className="graphic"
            src="/idkrx-landing-graphics-2.gif"
            alt="See your medications"
          /> */}
          <div className="low-text">
            See your medications in your dosages at your pharmacy.
          </div>
        </div>
      </ScrollContent>
    </div>
    <div>
      <ScrollContent direction="right">
        <div className="section-content">
          <video className="graphic" autoPlay loop muted playsInline>
            <source src="/idkrx-landing-graphics-1.webm" type="video/webm" />
            <source src="/idkrx-landing-graphics-1.mp4" type="video/mp4" />
            Your browser does not support the video tag. See your medications.
          </video>
          {/* <img
            className="graphic"
            src="/idkrx-landing-graphics-1.gif"
            alt="Vote on their availability for the day"
          /> */}
          <div className="low-text">
            Pick up your Rx and vote to let others know.
          </div>
        </div>
      </ScrollContent>
    </div>
    <div>
      <ScrollContent direction="left">
        <div className="section-content">
          <video className="graphic" autoPlay loop muted playsInline>
            <source src="/idkrx-landing-graphics-3.webm" type="video/webm" />
            <source src="/idkrx-landing-graphics-3.mp4" type="video/mp4" />
            Your browser does not support the video tag. See your medications.
          </video>
          {/* <img
            className="graphic"
            src="/idkrx-landing-graphics-3.gif"
            alt="Save your selections"
          /> */}
          <div className="low-text">
            Save your medication-pharmacy combinations.
          </div>
        </div>
      </ScrollContent>
    </div>
  </div>
);

const App: React.FC = () => {
  useEffect(() => {
    const color = "#ff0977";
    updateThemeColor(color);
  }, []);

  return (
    <div className="App">
      <Header />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
