import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer: React.FC = () => {
  return (
    <footer className="footer py-4 curved-footer">
      <Container>
        <Row>
          <Col md={3}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="/docs/about"
                  className="text-white no-dec"
                  aria-label="About Us"
                >
                  About Us
                </a>
              </li>

              <li>
                <a
                  href="/docs/privacy"
                  className="text-white no-dec"
                  aria-label="Privacy Policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/docs/terms"
                  className="text-white no-dec"
                  aria-label="Terms of Service"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>Contact Us</h5>
            <p>
              <a
                href="mailto:support@idkrx.com"
                className="mailto-link"
                aria-label="mail support"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </p>
          </Col>
          <Col md={3}>
            <h5>Follow Us</h5>
            <p>
              <a
                href="https://x.com/idkRxApp"
                target="_blank"
                className="text-white me-2"
                rel="noreferrer"
                aria-label="idkRx-X"
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </p>
          </Col>
          <Col md={3} className="text-center">
            <picture>
              <source srcSet="/rx_big_new_no_bg.avif" type="image/avif" />
              <source srcSet="/rx_big_new_no_bg.webp" type="image/webp" />
              <img
                src="/rx_big_new_no_bg.png"
                style={{ height: "180px" }}
                alt="idkRx Logo"
              />
            </picture>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p>Gripped & Shipped in Raleigh, NC.</p>
            <p>© {new Date().getFullYear()} idkRx LLC. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

// convert public/rx_big_new_no_bg.png public/rx_big_new_no_bg.avif
